/* topページのカードスタイル */
.MuiPaper-root.MuiCard-root.top-card {
        margin-left: auto;
        margin-right: auto;
        margin-top:30px;
        width: 300px;
        border: 1px solid #881122;
}

.MuiPaper-root.MuiCard-root.top-card  a {
        text-decoration: none;
        color : #881122;
}

.MuiPaper-root.MuiCard-root.top-card  button {
        text-align: center;
}

/* チケットのカードスタイル */
.MuiPaper-root.MuiCard-root.ticket-card {
        margin-left: auto;
        margin-right: auto;
        margin-top:30px;
        width: 300px;
        border: 1px solid #881122;
}