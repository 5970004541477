.table-parent {
    overflow: auto;
    height: 600px;
}
table {
    /* table-layout: fixed; */
    width:98%;
    /* border-collapse: collapse; */
    /* border: 1px solid #881122; */
    /* margin-left: 2px; */
    
}

th {
    font-size: 14px;
}

.fixed-header {
    position: sticky;
    top: 0;
    color: #fff;
    background: #881122;
}

.fixed-header ::before{
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 70%;
    border: 1px solid #ccc;
}

thead th:nth-child(1) {
    width: 3%;
}

thead th:nth-child(2) {
    width: 25%;
}

thead th:nth-child(3) {
    width: 25%;
}

thead th:nth-child(4) {
    width: 10%;
}

thead th:nth-child(5) {
    width: 25%;
}

thead th:nth-child(6) {
    width: 25%;
}

td {
    font-size: 12px;
  }