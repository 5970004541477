/* 見出しの文字*/
.content-header {
    color : #881122;
    font-size: 20pt;
}

/* 発行ボタン */
.MuiButton-root.btn-positive {
    border: 1px solid #881122;
    color : #881122;
}
.MuiButton-root.btn-positive:hover {
    transform: scale(1.2);
}

/* キャンセルボタン */
.MuiButton-root.btn-negative {
    background-color: #881122;
    color : #eeeeee;
}

.MuiButton-root.btn-negative:hover {
    background-color: #881122;
    color : #eeeeee;
    transform: scale(1.2);
}

/* 入力項目の枠組み */
.input-content-border {
    display: inline-block;
    padding-top: 30px;
}
/* 入力項目の見出し */
.input-content-border  .input-content-header {
    margin-top:auto;
}

.input-content-border .MuiFormControl-root {
    text-align: right;
}
/* 入力項目のTextField */
.input-content-border  .MuiTextField-root.input-content-value  .MuiOutlinedInput-root{        
    width: 300px;
}

.input-content-border  .MuiTextField-root.input-content-value  input {
    text-align: right;
}

/* csv出力ボタン */
.csv-export-button {
    margin-left: 30px;
    border: 1px solid #09e909;
    color : #ffffff;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    letter-spacing: 0.02857em;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    background: #2de44c;
    width: 100px;
    height:38px;
}

.csv-export-button:hover {
    transform: scale(1.2);
}